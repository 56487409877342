/** @jsx jsx */
import React from "react"
import { jsx, Box } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductsList from "../components/products-list"
import HeaderCard from "../components/header-card"
import TagsConstructor from "../components/tags"
import jamstackStyle from "../components/jamstack.styles"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    products: allStrapiProducts(
      limit: 1000
      filter: {editorChoice: {eq: true}},
      sort: { order: DESC, fields: published_at}
    ) {
      totalCount
      edges {
        node {
          id
          created_at
          updated_at
          slug
          prefix
          title
          meta_description
          productType {
            githubURL
            snipcartGUID
            price
          }
          styling {
            title
          }
          liveDemo
          headlessCMS {
            title
          }
          ssg {
            title
          }
          author {
            title
            slug
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [416, 832, 1248, 1664]
              )
            }
          }
        }
      }
    }
  }
`

class JamstackPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showPosts: 12 }

    this.handleLoadMore = this.handleLoadMore.bind(this)
  }

  handleLoadMore() {
    this.setState(state => ({
      showPosts: state.showPosts + 9,
    }))
  }

  render() {
    const {
      products,
      productCount = products.totalCount,
    } = this.props.data
    return (
      <Layout>
        <SEO
          title="Website templates for Jamstack • Stackrole"
          description="Handpicked website templates for building your Jamstack website"
        />
        <div sx={jamstackStyle.navBg}>
          <div sx={jamstackStyle.navContainer}>
            <TagsConstructor {...jamstackStyle.navDropdown} />
          </div>
        </div>
        <div sx={jamstackStyle.container}>
          <Box sx={jamstackStyle.containerBox}>
            <section sx={jamstackStyle.containerSection}>
              <HeaderCard>
                <h1>Website templates for Jamstack</h1>
                <p>Handpicked website templates for building your Jamstack website. Jamstack templates are high-performant websites that deliver low time to first byte. All of your pages are pre-built during deployment.</p>
                <p>All your code stays on github, so you can easily keep track of all the changes. Some website templates come bundled with Headless CMS and Makrdown option, This make your life easier when managing your website.</p>
              </HeaderCard>
              <ProductsList
                data={products}
                isHome={false}
                showPosts={this.state.showPosts}
              />
              {this.state.showPosts < productCount && (
                <button
                  onClick={this.handleLoadMore}
                  className="button"
                  sx={{ variant: "variants.paginationButton" }}
                >
                  Load more
                </button>
              )}
            </section>
          </Box>
        </div>
      </Layout>
    )
  }
}

export default JamstackPage
