/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import Featured from "./featured-card"
import ProductCard from "../components/product-card"
import { RiArrowRightLine } from "react-icons/ri"
import LinkButton from "./link-button"

const ProductsListConstructor = ({ data, isHome, showPosts, postCount, editorChoice }) => {
  const getProducts = data.edges
  const products = getProducts.slice(0, showPosts).map(edge => {
    const {
      id,
      slug,
      prefix,
      featuredImage,
      title,
      meta_description,
      productType,
      author,
    } = edge.node
    const path = prefix + slug
    const props = {
      id,
      slug,
      prefix,
      featuredImage,
      title,
      meta_description,
      productType,
      author,
      path,
    }

    return <ProductCard key={edge.node.id} data={props} />
  })
  return (
    <div>
      {editorChoice === true && (
        <div sx={styles.horizontalHeader}>
          <h2>
            Handpicked Website templates
          </h2>
        </div>
      )}
      <Grid columns={[1, 2, 3]} sx={{ gridGap: 4 }}>
        {isHome === true && (
          <Featured
            heading="Latest Jamstack Templates"
            paragraph="Choose the right Static Site Generator, Headless CMS, and style that fits your needs and launch your website today."
            linkTo="/jamstack/"
            linkText="Show All"
          />
        )}
        {products}
      </Grid>
      {editorChoice === true && (
        <div sx={styles.buttonContainer}>
          <LinkButton to="/website-templates" className="button" sx={styles.button}>
            See All
            <span className="icon -right" role="img" aria-label="See All">
              <RiArrowRightLine />
            </span>
          </LinkButton>
        </div>
      )}
    </div>
  )
}
const ProductsList = props => {
  return (
    <ProductsListConstructor
      data={props.data}
      postCount={props.data.totalCount}
      showPosts={props.showPosts}
      isHome={props.isHome}
      editorChoice={props.editorChoice}
    />
  )
}

export default ProductsList

const styles = {
  horizontalHeader: {
    display: ["flex"],
    "justify-content": "space-between",
    "align-items": "center",
    mb: "24px",
    h2: {
      mb: 0,
    },
  },
  buttonContainer: {
    my: "24px"
  }
}
