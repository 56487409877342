/** @jsx jsx */
import { jsx, Card } from "theme-ui"
import { RiArrowRightLine } from "react-icons/ri"
import LinkButton from "./link-button"

const styles = {
  CardContainer: {
    p: 4,
    display: "grid",
    alignItems: "center",
    "&:hover": {
      boxShadow: "0 0 1px rgba(0,0,0,0.3)",
    },
  },
  h2: {
    fontSize: 4,
    fontWeight: "heading",
  },
  p: {
    pb: 3,
  },
}

export default function featuedCard(props) {
  return (
    <Card sx={styles.CardContainer}>
      <div>
        <h2 sx={styles.h2}>{props.heading}</h2>
        <p sx={styles.p}>{props.paragraph}</p>
        <LinkButton to={props.linkTo} className="button">
          {props.linkText}
          <span className="icon -right" role="img" aria-label={props.linkText}>
            <RiArrowRightLine />
          </span>
        </LinkButton>
      </div>
    </Card>
  )
}
